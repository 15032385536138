type QueryKey = {
	base: string[];
	all?: (filters: object) => readonly (string | object)[];
	single?: (filters: object) => readonly (string | object)[];
} & {
	[key: string]: (
		filters: object | string
	) => readonly (string | object)[] | undefined;
};

type CustomKeyConfig = {
	prefix?: 'all' | 'single'; // default 'base'
	key: string[];
};
type QueryKeyConfig = {
	base: string[];
	all?: boolean;
	single?: boolean;
	[key: string]: undefined | boolean | string[] | CustomKeyConfig;
};

export type QueryKeysConfig = {
	[key: string]: QueryKeyConfig;
};

const queryKeys: QueryKeysConfig = {
	login: {
		base: ['login'],
		data: {
			key: ['login-data'],
		},
	},
	analytics: {
		base: ['analytics'],
		componentsList: {
			key: ['components-list'],
		},
		visitAcquisitions: ['z-analytics-visits-acquisitions'],
		businessPerformance: ['z-analytics-visits-businessPerformance'],
		feedback: ['z-analytics-feedback'],
		lapsedCustomers: ['z-analytics-lapsed-customers'],
		merchantSales: ['z-analytics-merchant-sales'],
		overview: ['z-analytics-visits-overview'],
		peakHours: ['z-analytics-visits-peakHours'],
		pointActivity: ['z-analytics-visits-pointActivity'],
		rewardsRedeemed: ['z-analytics-rewards-redeemed'],
		branchAnalytics: ['z-analytics-branch-analytics'],
		punchesAnalytics: ['z-punches-analytics'],
	},
	articles: {
		base: ['z-articles'],
		all: true,
		single: true,
	},
	branches: {
		base: ['z-branches'],
		all: true,
		single: true,
		photos: {
			prefix: 'single',
			key: ['photos'],
		},
		workingDays: {
			prefix: 'single',
			key: ['working-days'],
		},
		deliveryDays: {
			prefix: 'single',
			key: ['delivery-days'],
		},
		deliveryAreas: {
			prefix: 'single',
			key: ['delivery-areas'],
		},
	},
	dashboard: {
		base: ['z-dashboard'],
		analyticsPaymentCards: {
			key: ['analytics', 'payment-cards'],
		},
		analyticsNewVsReturning: {
			key: ['analytics', 'old-vs-returning'],
		},
		transactionsValues: {
			key: ['analytics', 'transactions-values'],
		},
		newsFeed: {
			key: ['analytics', 'news-feed'],
		},
	},
	freemiumDashboard: {
		base: ['z-freemiumDashboard'],
		summary: {
			key: ['summary'],
		},
		businessRating: {
			key: ['businessRating'],
		},
		feedbackAnalytics: {
			key: ['feedbackAnalytics'],
		},
		visitSegments: {
			key: ['visitSegments'],
		},
		cardSalesPerformance: {
			key: ['cardSalesPerformance'],
		},
		branchPerformance: {
			key: ['branchPerformance'],
		},
		plans: {
			key: ['plans'],
		},
	},
	premiumDashboard: {
		base: ['z-premiumDashboard'],
		loyaltyPerformance: {
			key: ['loyaltyPerformance'],
		},
	},
	freemiumLoyalty: {
		base: ['z-freemiumDashboard'],
		memberInsights: {
			key: ['memberInsights'],
		},
		pointsInsights: {
			key: ['pointsInsights'],
		},
		settings: {
			key: ['settings'],
		},
		earned: {
			key: ['earned'],
		},
		burned: {
			key: ['burned'],
		},
		redeemed: {
			key: ['redeemed'],
		},
		pointsExpired: {
			key: ['pointsExpired'],
		},
		vouchersExpired: {
			key: ['vouchersExpired'],
		},
		downloads: {
			key: ['downloads'],
		},
	},
	consumerReport: {
		base: ['z-consumerReport'],
		all: true,
		listConsumerReports: {
			key: ['listConsumerReports'],
		},
	},
	transactions: {
		base: ['z-transactions'],
		all: true,
		summary: {
			key: ['transactinosSummary'],
		},
		listTransactions: {
			key: ['listTransactions'],
		},
		downloads: {
			key: ['downloads'],
		},
	},
	exports: {
		base: ['z-exports'],
		visits: {
			key: ['visits'],
		},
		customers: {
			key: ['customers'],
		},
		promocodes: {
			key: ['promocodes'],
		},
		wheelOfFortune: {
			key: ['wheelOfFortune'],
		},
	},
	feedback: {
		base: ['z-feedback'],
		all: true,
		overview: {
			key: ['overview'],
		},
	},
	gallery: {
		base: ['z-gallery'],
		photos: {
			key: ['photos'],
		},
		videos: {
			key: ['videos'],
		},
	},
	giftCards: {
		base: ['z-gift-cards'],
		report: {
			key: ['report'],
		},
		all: true,
	},
	magicLink: {
		base: ['z-magicLink'],
		all: true,
		getDetails: {
			key: ['getDetails'],
		},
		verifyCode: {
			key: ['verifyCode'],
		},
	},
	menu: {
		base: ['z-menus'],
		single: true,
		allBranchesMenus: {
			key: ['branches'],
		},
		menuItems: {
			key: ['menuItems'],
		},
	},
	menuRevamp: {
		base: ['z-menuRevamp'],
		mainMenu: {
			key: ['mainMenu'],
		},
		addonGroups: {
			key: ['addon-groups'],
		},
		branchMenuItem: {
			key: ['branch-menu-item'],
		},
	},
	orders: {
		base: ['z-orders'],
		calendar: {
			key: ['calendar'],
		},
		all: true,
		single: true,
	},
	technicians: {
		base: ['z-technicians'],
		all: true,
	},
	promocode: {
		base: ['z-promocodes'],
		all: true,
		applicableFor: {
			key: ['applicable-for'],
		},
		report: {
			key: ['report'],
		},
	},
	recommendations: {
		base: ['z-recommendations'],
		all: true,
	},
	records: {
		base: ['z-records'],
		activities: {
			key: ['activities'],
		},
		customers: {
			key: ['customers'],
		},
		members: {
			key: ['members'],
		},
		redeems: {
			key: ['redeems'],
		},
		vouchers: {
			key: ['vouchers'],
		},
		customerVisits: {
			key: ['customerVisits'],
		},
	},
	rewards: {
		base: ['z-rewards'],
		loyaltyModel: {
			key: ['loyalty-model'],
		},
		stampCards: {
			key: ['stamp-cards'],
		},
		pointsAndRewards: {
			key: ['points-and-rewards'],
		},
		multiplePunches: {
			key: ['multiplePunches'],
		},
		punchProperties: {
			key: ['punchProperties'],
		},
		welcomeReward: {
			key: ['welcomeReward'],
		},
	},
	settings: {
		base: ['z-settings'],
		categories: {
			key: ['categories'],
		},
		profile: {
			key: ['profile'],
		},
		subscription: {
			key: ['subscription'],
		},
		dynamicSlot: {
			key: ['dynamicSlot'],
		},
		paymentMethods: {
			key: ['paymentMethods'],
		},
		booking: {
			key: ['booking'],
		},
		refundPolicies: {
			key: ['refundPolicies'],
		},
		languageOptions: {
			key: ['languageOptions'],
		},
		posLanguage: {
			key: ['posLanguage'],
		},
		billingAndPayments: { key: ['billingAndPayments'] },
		SubscriptionDetails: { key: ['SubscriptionDetails'] },
	},
	stories: {
		base: ['z-stories'],
		all: true,
		categories: {
			key: ['categories'],
		},
		categoryStories: {
			key: ['category-stories'],
		},
		archived: {
			key: ['archived'],
		},
	},
	teamMembers: {
		base: ['z-teamMembers'],
		permissions: {
			key: ['permissions'],
		},
		roles: {
			key: ['roles'],
		},
		teamApp: {
			key: ['teamApp'],
		},
		businessAdmins: {
			key: ['business-admins'],
		},
		businessAdmin: {
			key: ['business-admins', 'business-admin'],
		},
		branchTechnicians: {
			key: ['z-branch-technicians-not-paginated'],
		},
	},
	visitTransaction: {
		base: ['z-visitTransaction'],
		all: true,
		visitsSummaryAnalytics: {
			key: ['visits-summary-analytics'],
		},
	},
	topUp: {
		base: ['z-topUp'],
		plans: {
			key: ['plans'],
		},
		paymentCards: {
			key: ['payment-cards'],
		},
		paymentKey: {
			key: ['payment-key'],
		},
		credit: {
			key: ['current-credit'],
		},
	},
	billing: {
		base: ['z-billing'],
		invoices: {
			key: ['invoices'],
		},
	},
	wheelOfFortune: {
		base: ['z-wheel-of-fortune'],
		all: true,
		single: true,
		trashed: {
			key: ['trashed'],
		},
		redemptions: {
			key: ['redemptions'],
		},
		report: {
			key: ['report'],
		},
	},
	requestsVerification: {
		base: ['z-requestsVerification'],
		single: true,
		all: true,
		stats: {
			key: ['requestsStats'],
		},
	},
	receipts: {
		base: ['z-receipts'],
		all: true,
	},
	sidebarSettings: {
		base: ['z-sidebarSettings'],
		all: true,
	},
	redemptions: {
		base: ['z-redemptions'],
		all: true,
	},
	giftFriend: {
		base: ['z-giftFriend'],
		all: true,

		requestStatus: {
			key: ['requestStatus'],
		},
		categories: {
			key: ['categories'],
		},
		discount: {
			key: ['discount'],
		},
		gifts: {
			key: ['gifts'],
		},
	},
	campaigns: {
		base: ['z-campaigns'],
		all: true,
		single: true,
		summary: {
			key: ['summary'],
		},
		goals: {
			key: ['goals'],
		},
		actions: {
			key: ['actions'],
		},
		segments: {
			key: ['segments'],
		},
		channels: {
			key: ['channels'],
		},
		branches: {
			key: ['branches'],
		},
		menuItems: {
			key: ['menuItems'],
		},
		templates: {
			key: ['templates'],
		},
		promocodes: {
			key: ['promocodes'],
		},
		plans: {
			key: ['plans'],
		},
		consultation: {
			key: ['consultation'],
		},
		setting: {
			key: ['setting'],
		},
		uuid: {
			key: ['uuid'],
		},
		paymentKey: {
			key: ['payment-key'],
		},
	},
	segments: {
		base: ['z-segments'],
		all: true,
	},
	tips: {
		base: ['z-tips'],
		all: true,
	},
	baristas: {
		base: ['z-baristas'],
		all: true,
		areas: {
			key: ['areas'],
		},
		services: {
			key: ['services'],
		},
	},
	subscriptions: {
		base: ['z-subscriptions'],
		all: true,
		single: true,
		categories: {
			key: ['subscriptionCategories'],
		},
	},
	selectors: {
		base: ['z-selectors'],
		all: true,
		menuItemBranchVariations: {
			key: ['menuItemBranchVariations'],
		},
	},
	stripe: {
		base: ['z-stripe'],
		all: true,
	},
	schedule: {
		base: ['z-schedule'],
		technicians: {
			key: ['technicians'],
		},
		branches: {
			key: ['branches'],
		},
	},
	tipTechnicians: {
		base: ['z-tipTechnicians'],
		all: true,
	},
	freemiumTeams: {
		base: ['z-freemiumTeams'],
		all: true,
		listAdministrators: {
			key: ['listAdminsistrators'],
		},
		showAdministrator: {
			key: ['showAdministrator'],
		},
		listTeamMembers: {
			key: ['listTeamMembers'],
		},
		showTeamMember: {
			key: ['showTeamMember'],
		},
	},
	branchTips: {
		base: ['z-branchTips'],
		all: true,
	},
	employeeEarningCards: {
		base: ['z-employeeEarningCards'],
		all: true,
	},
	blockedHours: {
		base: ['z-blocked-hours'],
		all: true,
	},
	calendar: {
		base: ['z-calendar'],
		blockedTimes: {
			key: ['blocked-times'],
		},
		technicianSlots: {
			key: ['technician-slots'],
		},
	},
	announcements: {
		base: ['z-featureAnnouncements'],
		all: true,
	},
	terminalSettings: {
		base: ['z-terminalSettings'],
		all: true,
		content: {
			key: ['content'],
		},
	},
	cardlink: {
		base: ['z-cardlink'],
		webhooks: {
			key: ['webhooks'],
		},
		message: {
			key: ['message'],
		},
		setupStatus: {
			key: ['setupStatus'],
		},
		termsAndConditions: {
			key: ['termsAndConditions'],
		},
	},

	cardLinkActivity: {
		base: ['z-cardLinkActivity'],
		all: true,
	},

	ordersStatusHistory: {
		base: ['z-orders-status-history'],
		all: true,
	},

	activityLog: {
		base: ['z-activityLog'],
		all: true,
	},
	translations: {
		base: ['z-translations'],
		all: true,
		files: {
			key: ['files'],
		},
		locales: {
			key: ['locales'],
		},
	},
	terminalManagement: {
		base: ['z-terminalManagement'],
		all: true,
		terminalDetails: {
			key: ['terminalDetails'],
		},
		branchTerminals: {
			key: ['branchTerminals'],
		},
		terminalStats: {
			key: ['terminalStats'],
		},
		tags: {
			key: ['tags'],
		},
	},
	freeOnboarding: {
		base: ['z-freeOnboarding'],
		all: true,
		overviewCards: {
			key: ['overviewCards'],
		},
		gettingStartedSettings: {
			key: ['gettingStartedSettings'],
		},
		faqs: {
			key: ['faqs'],
		},
		groups: {
			key: ['groups'],
		},
		guides: {
			key: ['guides'],
		},
		announcements: {
			key: ['announcements'],
		},
		guide: {
			key: ['guide'],
		},
	},
	onboardingSettings: {
		base: ['onboardingSettings'],
		all: true,
	},
};

export type QueryKeysMap = keyof typeof queryKeys;

type QueryKeys = {
	[key: QueryKeysMap]: QueryKey;
};

export function queryFactory(queryKeysConfig: QueryKeysConfig): QueryKeys {
	const queryKeys = Object.entries(queryKeysConfig).reduce(
		(acc, [moduleKey, query]) => {
			const { base, all, single, ...rest } = query;

			// @ts-ignore
			const queryKey: QueryKey = { base };

			if (all) {
				queryKey.all = (filters: object) => [...base, { ...filters }];
			}
			if (single) {
				queryKey.single = (filters: object) => [
					...base,
					{ __isSingle: true, ...filters },
				];
			}

			Object.entries(rest).forEach(([queryName, subKeyConfig]) => {
				const { key, prefix } = subKeyConfig as CustomKeyConfig;

				const baseKey = {
					all: (filters: object | string) => queryKey.all!(filters as object),
					single: (filters: object | string) =>
						queryKey.single!(filters as object),
					base: (filters: object | string) => [...queryKey.base, filters],
				};

				queryKey[queryName] = (filters) => [
					...baseKey[prefix ?? 'base'](filters),
					...key,
				];
			});

			return {
				...acc,
				[moduleKey]: queryKey,
			};
		},
		{} as QueryKeys
	);

	return queryKeys;
}

export const generatedKeys = queryFactory(queryKeys);
