import React from 'react';
import { useTranslation } from 'react-i18next';

import { getSnakeCase } from '@zeal/zeal-lib';
import { Flex, Label, MenuAside } from '@zeal/web-ui';

import SettingsMenu, { SettingsMenuItem } from './staticMenu';
import useSidebarSettings from '@app/data/useSidebarSettings';

const SettingsMenuCard = (
	<Flex isColumn gap="12" className="p-4">
		{SettingsMenu?.map((item) => {
			return <SettingCardMenu item={item} key={item.title} />;
		})}
	</Flex>
);

function SettingCardMenu({ item }: { item: SettingsMenuItem }) {
	const { t } = useTranslation('settings');

	const pageTitle = t(getSnakeCase(item.title));

	const { sidebarSettings } = useSidebarSettings();

	const translatedMenu = item.menu.map((menu) => ({
		...menu,
		children: t(getSnakeCase(menu.children)),
	}));

	const subMenus = translatedMenu.filter(
		(menu) => sidebarSettings[menu.api_key]?.visible
	);

	if (!subMenus.length) return <></>;

	return (
		<Flex isColumn gap="5">
			<Label.Mid100 key={item.title} variant="subdued" className="uppercase">
				{pageTitle}
			</Label.Mid100>
			<Flex isColumn gap="3">
				{subMenus?.map((i) => (
					<MenuAside key={i.to} {...i} className="!px-4 !p-2" />
				))}
			</Flex>
		</Flex>
	);
}

export default SettingsMenuCard;
